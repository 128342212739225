import {apiResponseStatuses, httpStatusCodes} from '../../config/constants/ApiServiceConstants';
import * as authActions from './Actions';
import { signUpService } from '../../services/api'
import * as treesActions from '../trees/Actions';

export const signUp = ({ 
    firstName, 
    lastName, 
    email, 
    password,
    reCaptcha_token,
    transactionDetail = null 
}) => async (dispatch) => {
    dispatch(
        authActions.updateSignUpStatus(true, apiResponseStatuses.IDLE),
    );

    try {
        let signupBodyData = {
            first_name: firstName,
            last_name: lastName,
            email,
            password,
            reCaptcha_token            
        }
        if(transactionDetail !== null){
            signupBodyData = {...signupBodyData, ...transactionDetail};
        }
        const response = await signUpService.signUp(signupBodyData);
        switch (response.httpStatusCode) {
            case httpStatusCodes.SUCCESS_OK: {
                // also have to check api response status
                const responseStatusCode = response.data?.status;

                if(parseInt(responseStatusCode) == 200) {
                    dispatch(
                        authActions.signUp(
                            apiResponseStatuses.SUCCESS,
                            response.data.message
                        ),
                    );
                } else {
                    dispatch(
                        authActions.updateSignUpStatus(
                            false,
                            apiResponseStatuses.ERROR,
                            response.data.message.toLowerCase().includes("exist") ? 
                            'Email id already registered' : response.data.message,
                        ),
                    );
                }

                // also after signup we need to clean up all the transactional details
                // if user had used CTA to reach here

                dispatch(treesActions.clearTreeTransaction())
                
                break;
            }
            default:
                // anything other than 200 means an error
                dispatch(
                    authActions.updateSignUpStatus(
                        false,
                        apiResponseStatuses.ERROR,
                        response.error,
                    ),
                );
                break;
        }
    } catch (error) {
        dispatch(
            authActions.updateSignUpStatus(
                false,
                apiResponseStatuses.ERROR,
                'Something went wrong, Please try-again after sometime.'
            ),
        );
    }
};

export const verifyAccount = (token) => async (dispatch) => {
    // when api call is happening we change the authentication redux state
    dispatch(authActions.updateAccountStatus(true, apiResponseStatuses.IDLE));

    try {
        const {data: {is_success: isSuccess, message}} = await signUpService.verifyAccount(token);
        let returnData = false;
        switch (isSuccess) {
            case true: {
                returnData = true;
                dispatch(authActions.verifyAccount(apiResponseStatuses.SUCCESS, 'Account verified successfully..!', true));
                break;
            }
            default:
                returnData = false;
                // anything other than 200 means an error
                dispatch(authActions.verifyAccount(apiResponseStatuses.ERROR, message, false));
                break;
        }

        return returnData;
    } catch (error) {
        // eslint-disable-next-line no-console
        dispatch(authActions.verifyAccount(apiResponseStatuses.ERROR, apiResponseStatuses.ERROR, error.message));
        return false;
    }
};
