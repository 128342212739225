/******************************************
 *  Author : Suraj Sharma
 *  Created On : Tue Dec 22 2020
 *  File : ActionCreators.js
 *******************************************/
import * as CustomerInfoActions from './customer/ThunkActions';
import * as AuthenticationActions from './authentication/ThunkActions';
import * as TreesActions from './trees/ThunkActions';
import * as SignUpAction from './signUp/ThunkActions';
import * as ForgotPasswordAction from './forgotPassword/ThunkActions';
import * as ImpossibleVideoAction from './impossibleVideo/ThunkActions';
import * as CompanyForestAction from './companyForest/ThunkActions';

const actionCreators = {
    ...CustomerInfoActions,
    ...AuthenticationActions,
    ...TreesActions,
    ...SignUpAction,
    ...ForgotPasswordAction,
    ...ImpossibleVideoAction,
    ...CompanyForestAction
};

export default actionCreators;
