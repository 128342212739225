import React from "react";
import FormError from "../../components/commons/form/Error";

export const isValidRequiredField = (value) => value !== "";

export const isValidEmail = (email) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  );

export const renderRequiredError = (field, isSubmitted, message) => {
  if (isSubmitted === true && !isValidRequiredField(field)) {
    return <FormError message={message} />;
  }

  return "";
};

export const renderInvalidEmailError = (value, isSubmitted) => {
  if (
    isSubmitted === true &&
    isValidRequiredField(value) &&
    !isValidEmail(value)
  ) {
    return <FormError message="Please add valid email address." />;
  }

  return "";
};

export const isSameAs = (value, isSubmitted, sameAs) => value === sameAs;

export const renderSameAsError = (
  value,
  isSubmitted,
  sameAs,
  fieldName,
  sameAsFieldName
) => {
  if (
    isSubmitted === true &&
    isValidRequiredField(value) &&
    !isSameAs(value, isSubmitted, sameAs)
  ) {
    return (
      <FormError
        message={`${fieldName} should be same as ${sameAsFieldName}`}
      />
    );
  }

  return "";
};

// export const isValidPassword = (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/.test(value)
// /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d@$!%*#?&]{6,}$/
export const isValidPassword = (value) =>
  /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{6,}$/.test(value);

export const isContainSpecialCharInPassoword = (value) => 
  /[-’\/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g.test(value);

export const renderPasswordError = (value, isSubmitted) => {

  if (
    isSubmitted === true &&
    isValidRequiredField(value) &&
    isContainSpecialCharInPassoword(value, isSubmitted)
  ) {    
    return (
      <FormError message="Your password should only contains letters and numbers" /> 
    );
  }
  
  if (
    isSubmitted === true &&
    isValidRequiredField(value) &&
    !isValidPassword(value, isSubmitted)
  ) {
    return (
      <FormError 
        message="Password should have minimum length of 6 characters which contain at least one lowercase letter, one uppercase letter" 
      />
    );
  }
  
  return "";
};
export const isValidTC = (value) => value === true;
export const renderTCError = (value, isSubmitted) => {
  if (
    isSubmitted === true &&
    isValidRequiredField(value) &&
    !isValidTC(value, isSubmitted)
  ) {
    return <FormError message="Please accept Terms of Service" />;
  }

  return "";
};
